import React from "react";
import { graphql } from "gatsby"

import Metadata from '../components/Metadata';
import PageLayout from "../layouts/Page";
import Hero from "../components/Hero";
import HubspotFormPancake from "../components/pancakes/HubspotForm";
import PreFooter from "../components/pancakes/PreFooter";

import "./ebook-page.scss";

export const query = graphql`
  query EbookQuery($id: String!) {
    ebook: sanityEbook(_id: { eq: $id }) {
      _id
      ebookTitle
      hubspotFormID
      _rawDescription
      slug {
        current
      }
      heroCoverImage {
        altText
        image {
          ...ImageWithPreview
        }
      }
      seo {
        metaDescription
        metaTitle
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;

const EbookPage = props => {

  const {ebook} = props.data

  return (
    <PageLayout>
      <Metadata 
        title={`${ebook.ebookTitle} eBook`} 
        description={ebook.seo?.metaDescription} 
        image={ebook.seo?.ogImage?.asset.url || null}
      />
      <Hero textAlign="Left" overlappingImage={ebook.heroCoverImage} className="EbookHero">
        <Hero.Title>
          <span>Download</span>
          {ebook.ebookTitle}
        </Hero.Title>
      </Hero>
      <HubspotFormPancake blocks={ebook._rawDescription} formId={ebook.hubspotFormID} className="EbookHubspotFormPancake" useChiliPiper={ebook.useChiliPiper} customChiliPiperRouter={ebook.customChiliPiperRouter} />
      <PreFooter theme="Light" title="What’s stopping you from switching to a cloud development environment?" />
    </PageLayout>
  );
}

export default EbookPage;
